/*=========================================================================================
  File Name: moduleLanguage.js
  Description: Language Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleSponsorState.js'
import mutations from './moduleSponsorMutations.js'
import actions from './moduleSponsorActions.js'
import getters from './moduleSponsorGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
