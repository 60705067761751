/*=========================================================================================
  File Name: moduleSponsorMutations.js
  Description: Sponsor Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.sponsors.unshift(item)
  },
  SET_Sponsor(state, Sponsors) {
    state.sponsors = Sponsors
  },
  UPDATE_Sponsor(state, Sponsor) {
    const SponsorIndex = state.sponsors.findIndex((p) => p.ID == Sponsor.ID)
    Object.assign(state.sponsors[SponsorIndex], Sponsor)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.sponsors.findIndex((p) => p.ID == itemId)
    state.sponsors.splice(ItemIndex, 1)
},
}
